<template>
  <div class="why_page" id="about">
      <div class="why_order">
          <div class="why_order_title">
              <b>{{ $t('aboutMsg')}}</b>
              <b class = "why_order_title_small">{{ $t('aboutReasonMsg')}}</b>
          </div>
          <div class="why_order_description">
              <p>{{ $t('about_paragraph_1') }}<br><br>
                  {{ $t('about_paragraph_2') }}<br><br>
                  {{ $t('about_paragraph_3') }}<br><br>
              </p>
              <p style="text-align: center">{{ $t('about_end')}}</p>
            </div>
      </div>
      <div class="why_logo"></div>
  </div>
</template>

<script>
export default {
  name: 'CodexWhy',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.why_order_title {
    width: 380px;
    height: 100px;
}

.why_order_description {
    width: 380px;
}

.why_logo {
    background-image: url("../assets/about.png");
}

@media only screen and (max-width: 1300px) { 
  .why_page {
    height: 1300px;
  }
}


@media only screen and (max-width: 830px) {
  .why_page {
    flex-direction: column-reverse;
  }

  .why_logo {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .why_order {
    margin-bottom: 0;
    height: fit-content;
  }

  .why_order_title {
    margin-top: 40px;
  }

  .why_order_description {
    margin-bottom: 40px;
  }

  .why_page {
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .why_page {
    height: fit-content;
    margin-top: 40px;
  }
  .why_logo {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .why_order_title {
    width: 260px;
  }

  .why_order_description {
      width: 260px;
      line-height: 20px;
      margin-bottom: 20px;
  }
}

</style>
