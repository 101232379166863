<template>
  <div class="why_page" id="why">
      <div class="why_order">
          <div class="why_order_title">
              <b>{{ $t('whyOrderMsg')}}</b>
              <b class = "why_order_title_small">{{ $t('whyOrderReasonMsg')}}</b>
          </div>
          <div class="why_order_description">
              <p>{{ $t('whyOrder_paragraph_1')}}<br><br>
                {{ $t('whyOrder_paragraph_2')}}<br><br>
                {{ $t('whyOrder_paragraph_3')}}<br><br>
                {{ $t('whyOrder_paragraph_4')}}<br><br>
              </p>
              <p style="text-align: center">{{ $t('whyOrder_end')}}</p>
            </div>
      </div>
      <div class="why_logo"></div>
  </div>
</template>

<script>
export default {
  name: 'CodexWhy',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.why_page {
  display: flex;
  flex-direction: row;
  height: 860px;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

.why_order, .why_logo {
  width: 49%;
  height: 100%;
}

.why_order{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.212);
}

.why_order_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
    font-size: 24px;
    letter-spacing: 6px;
    color: black;
    line-height: 40px;
    width: 450px;
    color:#000000;    
    text-shadow: 1px 0 #000000;
    margin-bottom: 20px;
    }

.why_order_title_small {
    font-size: 16px;
    letter-spacing: 3px;
    font-style: italic;
    text-shadow: none;
}

.why_order_description {
    width: 450px;
    text-align: justify;
    line-height: 22px;
}

.why_logo {
    background-image: url("../assets/why.png");
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 1300px) {
  .why_page {
    flex-direction: column;
    height: 1380px;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
  }
  .why_order, .why_logo {
    width: 100%;
    height: 49%;
    margin-bottom: 0;
  }
  .why_order {
    margin-bottom: 40px;
  }

  .why_order {
    height: fit-content;
  }

  .why_order_title {
    margin-top: 40px;
  }

  .why_order_description {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .why_page {
    height: fit-content;
    margin-bottom: 0;
  }

  .why_order {
    height: 70%;
    margin: 0;
  }

  .why_logo {
    display: none;
  }
  .why_order_title {
    font-size: 20px;
    letter-spacing: 4px;
    line-height: 35px;
    width: 400px;
    margin-top: 40px;
    margin-bottom: 0;
  }

  .why_order_title_small {
      font-size: 14px;
      letter-spacing: 2px;
      margin-bottom: 10px;
  }

  .why_order_description {
      width: 400px;
      line-height: 20px;
  }
}

@media only screen and (max-width: 580px) {
  .why_order_title {
    line-height: 25px;
    width: 350px;
    margin-bottom: 15px;
    margin-top: 50px;
    height: 110px;
  }

  .why_page {
    height: fit-content;
  }

  .why_logo {
    display: none;
  }

  .why_order {
    height: 100%;
    margin-bottom: 0;
  }

  .why_order_title_small {
      margin-bottom: 10px;
  }

  .why_order_description {
      width: 350px;
      line-height: 20px;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .why_order_title {
    width: 260px;
    margin-bottom: 0;
    height: fit-content
  }

  .why_order_title b {
    margin-bottom: 20px;
  }

  .why_order_description {
      width: 260px;
      line-height: 20px;
  }
}
</style>
