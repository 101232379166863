<template>
  <div class="welcome_page" id="welcome_page">
    <b>{{ $t('welcomeMsg_part1') }}<br>{{ $t('welcomeMsg_part2')}}</b>
    <p>{{ $t("welcomeMsg_description")}}</p>
  </div>
</template>

<script>
export default {
  name: 'CodexWelcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.welcome_page {
  display: flex;
  flex-direction: column;
  height: 800px;
  width: 100%;
  background-image: url("../assets/welcome.png");
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.welcome_page b {
  color: white;
  text-shadow: 1px 0 white;
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 5px;
  margin-bottom: 120px;
  line-height: 150%;
}

.welcome_page p {
  color: white;
  text-shadow: 1px 0 white;
  font-size: 20px;
  font-style:oblique;
  font-weight: 900;
  letter-spacing: 2px;
  max-width: 650px;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .welcome_page b {
    font-size: 50px;
  }
  .welcome_page p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .welcome_page {
    height: 700px;
  }
  .welcome_page b {
    font-size: 40px;
    margin: 0 20px 100px

  }
  .welcome_page p {
    font-size: 14px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

</style>
