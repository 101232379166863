<template>
  <div id="app">
    <CodexHeader/>
    <router-view/>
  </div>
</template>

<script>
import CodexHeader from './components/Codex-header'

export default {
  name: 'App',
  components: {
    CodexHeader
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

a {
  cursor: pointer;
}
</style>
