<template>
  <div class="why_page" id="how">
      <div class="how_calculator" v-if="calculatorShow">
          <b>{{ $t('calculateHeader')}}</b>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_1')}}</p>
              <div class="calculator_buttons_block">
                  <div @click="append('data_source', $t('calculate_block_1_price_1'), $event)" class="calculator_button data_source">{{ $t('calculate_block_1_button_1')}}</div>
                  <div @click="append('data_source', $t('calculate_block_1_price_2'), $event)" class="calculator_button data_source">{{ $t('calculate_block_1_button_2')}}</div>
                  <div @click="append('data_source', $t('calculate_block_1_price_3'), $event)" class="calculator_button data_source">{{ $t('calculate_block_1_button_3')}}</div>
                  <div @click="append('data_source', $t('calculate_block_1_price_4'), $event)" class="calculator_button data_source">{{ $t('calculate_block_1_button_4')}}</div>
              </div>
          </div>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_2')}}</p>
              <div class="calculator_buttons_block">
                  <div class="calculator_button how_many_source">
                      <input v-model="howManyDataSource" @change="calculate()" class="calculator_button_input" type="text" pattern="\d*" maxlength="3">
                  </div>
              </div>
          </div>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_3')}}</p>
              <div class="calculator_buttons_block">
                  <div @click="append('diagramm_types', $t('calculate_block_3_price_1'), $event, true)" class="calculator_button diagramm_types">{{ $t('calculate_block_3_button_1')}}</div>
                  <div @click="append('diagramm_types', $t('calculate_block_3_price_2'), $event, true)" class="calculator_button diagramm_types">{{ $t('calculate_block_3_button_2')}}</div>
                  <div @click="append('diagramm_types', $t('calculate_block_3_price_3'), $event, true)" class="calculator_button diagramm_types">{{ $t('calculate_block_3_button_3')}}</div>
                  <div @click="append('diagramm_types', $t('calculate_block_3_price_4'), $event, true)" class="calculator_button diagramm_types">{{ $t('calculate_block_3_button_4')}}</div>
              </div>
          </div>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_4') }}</p>
              <div class="calculator_buttons_block">
                <div class="calculator_button">
                        <input v-model="howManyDiagrams" @change="calculate()" class="calculator_button_input" type="text" pattern="\d*" maxlength="3">
                </div>
              </div>
          </div>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_5')}}</p>
              <div class="calculator_buttons_block yes_no">
                  <div @click="append('specific_design', $t('calculate_block_5_price_yes'), $event)" class="calculator_button specific_design yes">{{ $t('calculate_button_yes')}}</div>
                  <div @click="append('specific_design', $t('calculate_block_5_price_no'), $event)" class="calculator_button specific_design">{{ $t('calculate_button_no')}}</div>
              </div>
          </div>
          <div class="how_calculator_line">
              <p>{{ $t('calculate_block_6')}}</p>
              <div class="calculator_buttons_block yes_no">
                  <div @click="append('suitable_for_screen', $t('calculate_block_6_price_yes'), $event)" class="calculator_button suitable_for_screen yes">{{ $t('calculate_button_yes')}}</div>
                  <div @click="append('suitable_for_screen', $t('calculate_block_6_price_no'), $event)" class="calculator_button suitable_for_screen">{{ $t('calculate_button_no')}}</div>
              </div>
          </div>
          <div class="calculator_buttons_block" style="justify-content: flex-end;">
            <div class="total_button">Total: {{total || "0"}}{{ $t('calculate_block_currency')}}</div>
            <a @click="goTo('contacts')" id="orderDash" style="text-decoration: none"><div class="order_button">{{ $t('order_dashboard_text')}}</div></a>
          </div>
      </div>
      <div class="why_logo" v-if="!calculatorShow"></div>
      <div class="why_order">
          <div class="why_order_title">
              <b>{{ $t('howRecieveMsg')}}</b>
              <b class = "why_order_title_small">{{ $t('howRecieveReasonMsg')}}</b>
          </div>
          <div class="why_order_description">
              <p>{{ $t('howRecieve_paragraph_1') }}<br><br>
                {{ $t('howRecieve_paragraph_2') }}<br><br>
                {{ $t('howRecieve_paragraph_3') }}<br><br>
                {{ $t('howRecieve_paragraph_4') }}<br><br>
              </p>
              <p style="text-align: center">{{ $t('howRecieve_end') }}</p>
            </div>
      </div>
  </div>
</template>

<script>
import calcStatus from "../plugins/showCalculator.json";

export default {
  name: 'CodexWhy',
  data() {
      return {
          buttonDefaultColor: "rgba(128, 128, 128, 0.212)",
          buttonSelectedColor: "rgba(124, 224, 255, 0.747)",
          howManyDataSource: "0",
          howManyDiagrams: "0",
          total: "0",
          calculatorShow: calcStatus.showCalculator,
          diagramTypesNeedMultiply: true,
          calculatorUsed: false,
          buttonValues: {"data_source" : "0", "diagramm_types": "0", "specific_design": "0", "suitable_for_screen": "0"}
      }
  },
  methods: {
      append(type, count, e, diagramType = false) {

        if (!this.calculatorUsed) {
            this.sendGA('codex_calculator');
            this.calculatorUsed = true;
        }

        document.getElementsByClassName(`calculator_button ${type}`).forEach(element => {
            element.removeAttribute("style")
        });

        this.buttonValues[`${type}`] = count;

        e.target.setAttribute("style", `background-color: ${this.buttonSelectedColor};`);

        if (type === "diagramm_types") {
            this.diagramTypesNeedMultiply = diagramType;
        }
        this.calculate();
      },
      calculate() {
        if (this.diagramTypesNeedMultiply) {
            this.total = (parseInt(this.buttonValues.data_source) * parseInt(this.howManyDataSource)) + (parseInt(this.buttonValues.diagramm_types) * parseInt(this.howManyDiagrams))+ parseInt(this.buttonValues.specific_design) + parseInt(this.buttonValues.suitable_for_screen);
        } else {
            this.total = (parseInt(this.buttonValues.data_source) * parseInt(this.howManyDataSource)) + parseInt(this.buttonValues.diagramm_types) + parseInt(this.buttonValues.specific_design) + parseInt(this.buttonValues.suitable_for_screen);
        }
      },
      goTo(elementTo) {
      let elementFound = document.getElementById(elementTo);
      elementFound.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.why_logo {
    background-image: url("../assets/calculator.png");
}

.why_order {
    background-color: rgba(0, 195, 255, 0.747);
}

.why_order_title {
    font-size: 20px;
    height: 160px;
}

.how_calculator {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.how_calculator b {
    font-weight: bold;
    font-size: 24px;
    text-shadow: 1px 0 black;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.how_calculator_line {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 10px;
}

.how_calculator_line p {
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 0 20px;
    text-align: left;
}

.calculator_buttons_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;   
}

.calculator_buttons_block.yes_no{
    justify-content: flex-start;
}

.calculator_button, .total_button, .order_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.1s ease-in;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.212);
    width: 189px;
    height: 50px;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: bold;
}

.total_button, .order_button {
    margin: 20px 20px 0 15px;
    font-size: 18px;
    font-weight: bold;
}

.order_button {
    font-size: 17px;
    width: 250px;
    background-color: rgba(124, 224, 255, 0.747);
    cursor: pointer;
    color: black;
    animation: shake 2s infinite;
}

@keyframes shake {
    11% {transform: translateY(0px);}
    22% {transform: translateY(2px);}
    33% {transform: translateY(-4px);}
    44% {transform: translateY(4px);}
    55% {transform: translateY(-4px);}
    66% {transform: translateY(4px);}
    77% {transform: translateY(-2px);}
    88% {transform: translateY(0px);}
}

.yes {
    margin-right: 40px;
}

.calculator_button:hover {
    background-color: #ccecfa;
}

.calculator_button_input {
    -webkit-appearance: none;
    width: 40%;
    height: 50%;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media only screen and (max-width: 1700px) {
  .why_page {
    flex-direction: column-reverse;
    height: 1600px;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
  }
  .how_calculator {
      width: 65%;
      margin: 0 auto
  }

  .why_order, .why_logo {
    width: 100%;
    height: 49%;
  }
  .why_order {
    margin-bottom: 40px;
  }

  .why_order_title {
      margin-top: 40px;
  }

  .why_order_description {
      margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1300px) {
  .why_page {
      flex-direction: column-reverse;
  }
  .how_calculator {
      width: 80%;
      margin: 0 auto
  }
  .why_order b {
      margin-top: 40px;
  }

  .why_order_title {
      height: fit-content;
  }

  .why_order_description {
      margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1050px) {
  .how_calculator {
      width: 95%;
      margin: 0 auto
  }
  .why_order_title {
      margin-top: 0;
  }
}

@media only screen and (max-width: 900px) {
  .how_calculator {
      width: 100%;
      margin: 0 auto
  }
}

@media only screen and (max-width: 830px) {
  .how_calculator {
      display: none;
  }

  .why_page {
      height: fit-content;
  }

  .why_order {
      height: 100%;
      margin-bottom: 0;
  }

  .why_order_title{ 
      margin-top: 0;
  }

  .why_logo {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .why_order_title b{ 
      margin-top: 0;
  }
  .why_order_title{ 
      margin-top: 40px;
  }
}

</style>
