<template>
  <div class="home">
    <CodexWelcome/>
    <CodexWhy/>
    <CodexHow/>
    <CodexAbout/>
    <CodexQuestions/>
    <CodexFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import CodexWelcome from '../components/Codex-welcome'
import CodexWhy from '../components/Codex-why'
import CodexHow from '../components/Codex-how'
import CodexAbout from '../components/Codex-about'
import CodexQuestions from '../components/Codex-questions'
import CodexFooter from "../components/Codex-footer"

export default {
  name: 'Home',
  components: {
    CodexWelcome,
    CodexWhy,
    CodexHow,
    CodexAbout,
    CodexQuestions,
    CodexFooter
  }
}
</script>
