<template>
  <div class="contacts_page" id="contacts">
      <div class="contacts_info">
          <b>{{ $t('questionsMsg')}}<br> {{ $t('questionsContactMsg')}}</b>
          <p>{{ $t('questions_adress')}}</p>
          <p>{{ $t('questions_email')}}</p>
          <p>{{ $t('questions_phone')}}</p>
          <div class="social_bar">
              <a href="https://www.facebook.com/CodexSoftware/" target="_blank" rel="noopener noreferrer" @click="sendGA('codex_social_facebook');"><img src="../assets/facebook.png" alt="Facebook"></a>
              <a href="https://twitter.com/codex_software" target="_blank" rel="noopener noreferrer" @click="sendGA('codex_social_twitter');"><img src="../assets/twitter.png" alt="Twitter"></a>
              <a href="https://www.linkedin.com/company/codex-software/" target="_blank" rel="noopener noreferrer" @click="sendGA('codex_social_linkedin');"><img src="../assets/linkedin.png" alt="Linkedin"></a>
          </div>
      </div>
      <div class="contacts_form">
          <form class="question_form">
              <input class = "input_name" v-model="name" type="text" v-bind:placeholder="$t('questions_form_name')"/>
              <input class = "input_email" v-model="email" type="email" v-bind:placeholder="$t('questions_form_email')"/>
              <input class = "input_phone" v-model="phone" type="number" v-bind:placeholder="$t('questions_form_phone')"/>
              <input class = "input_adress" v-model="adress" type="text" v-bind:placeholder="$t('questions_form_adress')"/>
              <input class = "input_object" v-model="object" type="text" v-bind:placeholder="$t('questions_form_object')"/>
              <textarea class = "textarea_message" v-model="message" type="text" v-bind:placeholder="$t('questions_form_message')"/>
              <input  class="button_send_form" @click="sendEmail()" v-bind:value="$t('questions_form_send')" readonly>
              <b id="thanks_for_message">{{ $t('questions_form_thanks')}}</b>    
          </form>
      </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'CodexWhy',
  data() {
      return {
          name: "",
          email: "",
          phone: "",
          adress: "",
          object: "",
          message: ""
      }
  },
  methods: {
    sendEmail() {
      console.log("Hereee");
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)) {
        if (this.name.length > 0 && this.email.length > 0 && this.phone.length > 0) {
            try {
            emailjs.send('service_7767wpn', 'template_2205ddo', {
            name: this.name,
            email: this.email,
            message: this.message,
            phone: this.phone,
            adress: this.adress,
            object: this.object
            },
            'user_yn1SP7u2MLwRHJqxHIwZe')

            } catch(error) {
                console.log({error})
            }
            // Reset form field
            this.name = ""
            this.email = ""
            this.message = ""
            this.phone = ""
            this.adress = ""
            this.object = ""
            document.getElementById("thanks_for_message").style.opacity = 1;
            this.sendGA('codex_contacts_form_success');
        }
        else {
            alert("Enter your name, email and phone number, please.")
        }
      }
      else {
          alert('Enter correct email, please.')
      }
    },
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contacts_page {
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  background-color: rgba(0, 195, 255, 0.747);
}

.contacts_info, .contacts_form {
  width: 50%;
  height: 100%;
}

.contacts_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.contacts_info b {
    font-size: 25px;
    color: black;
    text-shadow: 1px 0px black;
    letter-spacing: 3px;
    margin: 60px 0 20px;
    line-height: 160%;
}

.social_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100px;
    margin: 35px 0;
}

.social_bar img {
    width: 20px;
    height: 20px;
}

.contacts_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.question_form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 400px;
    height: fit-content;
  
}

.input_name, .input_email, .input_phone, .input_adress, .input_object, .textarea_message {
    outline: none;
    margin: 0;
    padding: 0;
    border: 0;
    width: 46%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid black;
    color: black;
    font-weight: bold;
    padding-left: 3%;
    margin-bottom: 10px;
}

.input_object, .textarea_message {
    width: 100%;
}

.textarea_message {
    height: 80px;
    resize: none;
    margin: 15px 0 40px;
}

::placeholder {
    opacity: 1;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

.input_name, .input_phone {
    margin-right: 1%;
}

.input_name {
    margin-bottom: 30px;
}

.button_send_form {
    width: 100%;
    height: 45px;
    background-color: black;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.question_form b {
    margin: 10px auto 0;
}

#thanks_for_message {
    opacity: 0;
    transition: 0.2s ease-in;
}

@media only screen and (max-width: 1060px) {
  .contacts_page {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    background-color: rgba(0, 195, 255, 0.747);
  }

  .contacts_info {
      margin-bottom: 40px;
      border-bottom: 1px solid black;
  }
}

@media only screen and (max-width: 600px) {
  .input_name, .input_email, .input_phone, .input_adress, .input_object {
      width: 100%;
      margin-right: 0;
  }

  .contacts_info b {
    font-size: 22px;
    letter-spacing: 2px;
    margin: 50px 0 15px;
  }
  .contacts_info p {
      font-size: 15px;
  }

}

@media only screen and (max-width: 500px) {
  .contacts_form {
      width: 80%;
  }
  .question_form {
      width: 100%;
  }

}

</style>
