<template>
  <div class="footer_page">
    <div class="social_bar">
        <a href="https://www.facebook.com/CodexSoftware/" @click="sendGA('codex_social_facebook');" target="_blank" rel="noopener noreferrer"><img src="../assets/facebook.png" alt="Facebook"></a>
        <a href="https://twitter.com/codex_software" @click="sendGA('codex_social_twitter');" target="_blank" rel="noopener noreferrer"><img src="../assets/twitter.png" alt="Twitter"></a>
        <a href="https://www.linkedin.com/company/codex-software/" @click="sendGA('codex_social_linkedin');" target="_blank" rel="noopener noreferrer"><img src="../assets/linkedin.png" alt="Linkedin"></a>
    </div>
    <b>©2021 Codex Software Solutions.</b>
    <router-link class="private_policy" to="/policy">Privacy Policy</router-link>
  </div>
</template>

<script>
export default {
  name: 'CodexWelcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer_page {
  display: flex;
  flex-direction: column;
  height: 160px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.social_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100px;
    margin: 35px 0;
}

.social_bar img {
    width: 20px;
    height: 20px;
}

.footer_page b {
    font-size: 14px;
}

.private_policy {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}


@media only screen and (max-width: 500px) {
  .footer_page b, .private_policy {
    font-size: 12px;
  }
}

</style>
