<template>
  <div class="header" id="header">
      <div class="codex">
        <a href="https://www.codex-soft.com" target="_blank" rel="noopener noreferrer" @click="sendGA('codex_header_logo')"><img alt="Codex Logo" id="codex_logo" src="../assets/codex.png"></a>
        <router-link id="mainCodex" to="/" style="margin-right: 20px">{{ $t('headerMsg')}}</router-link>
        <div class="butter_menu" @click="butterAction()"></div>
      </div>
      <nav id="menu">
        <a class="nav_word" @click="goTo('why', 'codex_header_why')">{{ $t('whyMsg')}}</a>
        <a class="nav_word" @click="goTo('how', 'codex_header_how')">{{ $t('howMsg')}}</a>
        <a class="nav_word" @click="goTo('about', 'codex_header_about')">{{ $t('aboutUsMsg')}}</a>
        <a class="nav_word" @click="goTo('contacts', 'codex_header_contact')">{{ $t('contactsMsg')}}</a>
      </nav>
      <div class="language">
        <div class="block">
          <p>FR</p>
          <div class="flag FRN" @click="changeLanguage('fr')"></div>
        </div>
        <div class="block">
          <p>EN</p>
          <div class="flag GBR" @click="changeLanguage('en')"></div>
        </div>
      </div>
      <div id="small_menu">
        <a class="nav_word" style="margin-top: 15px" @click="goTo('why', 'codex_header_why')">{{ $t('whyMsg')}}</a>
        <a class="nav_word" @click="goTo('how', 'codex_header_how')">{{ $t('howMsg')}}</a>
        <a class="nav_word" @click="goTo('about', 'codex_header_about')">{{ $t('aboutUsMsg')}}</a>
        <a class="nav_word" @click="goTo('contacts', 'codex_header_contact')">{{ $t('contactsMsg')}}</a>
        <div class="language_small">
          <div class="block">
            <p>FR</p>
            <div class="flag FRN" @click="changeLanguage('fr')"></div>
          </div>
          <div class="block">
            <p>EN</p>
            <div class="flag GBR" @click="changeLanguage('en')"></div>
          </div>
      </div>
      </div>
    </div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  name: 'CodexHeader',
  props: {
    msg: String
  },
  data() {
      return {
        small_menu_active: false
      }
  },
  methods: {
    changeLanguage(lang) {
          try {
            this.small_menu_active = false;
            document.getElementById("small_menu").removeAttribute("style");
          }
          catch (e) { null }
          if (lang === "fr") this.sendGA('codex_header_french');
          else this.sendGA('codex_header_english');
            i18n.locale = lang; 
    },

    butterAction() {
      if (!this.small_menu_active) {
        document.getElementById("small_menu").setAttribute("style", "display: flex;");
      }
      else {
        document.getElementById("small_menu").removeAttribute("style");
      }
      this.small_menu_active = !this.small_menu_active;
    },

    async goTo(elementTo, GAEvent) {

      this.sendGA(GAEvent);

      try {
        this.small_menu_active = false;
        document.getElementById("small_menu").removeAttribute("style");
      }
      catch (e) { null }
      
      let elementFound = document.getElementById(elementTo);
      if (!elementFound) {
        await this.$router.push("/");
        elementFound = document.getElementById(elementTo);
        elementFound.scrollIntoView({behavior: "smooth", block: "start"});
      }
      else elementFound.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#mainCodex {
  color: black;
  text-decoration: none;
}

#codex_logo {
  width: 80px;
  height: 80px;
  margin-right: 50px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  height: 150px;
}

.codex a, #mainCodex{
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.codex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  align-items: center;
}

nav a {
  margin-right: 20px;
}

.nav_word {
  font-size: 16px;
  color: black;
  text-decoration: none;
  transition: 0.1s ease-in;
}

.nav_word:hover {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.26);
}

.language, .language_small {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 180px;
  height: 40px;
  border-radius: 15px;
  transition: 0.1s ease-in;
  box-shadow: 0 3px 7px rgba(128, 128, 128, 0.267);
  margin-right: 20px;
}

.language:hover {
  box-shadow: 0 5px 10px rgba(128, 128, 128, 0.589);
}

.language_small {
  margin: 0 0 15px 0;
}

.language .block, .language_small .block {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  align-items: center;
}

.language p, .language_small p {
  margin-right: 8px;
}

.language .flag, .language_small .flag{
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-size: cover;
}

.flag {
    cursor: pointer;
}

.flag.FRN {
  background-image: url("../assets/FRA.svg");
}

.flag.GBR {
  background-image: url("../assets/GBR.svg");
}

.butter_menu {
  margin-right: 20px;
  display: none;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-image: url("../assets/bars.svg");
  background-size: cover;
  cursor: pointer;
}

#small_menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: 220px;
  width: 100%;
  border-top: 2px solid black;
  height: 300px;
  background-color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 1230px) {
  #menu {
    display: none;
  }
  .language {
    display: none;
  }
  .codex {
    width: 100%;
    margin-left: 6%;
    justify-content: space-between;
  }
  #codex_logo {
    margin-right: 5%;
  }
  .butter_menu {
    display: block;
  }
}

@media only screen and (max-width: 530px) {
  #mainCodex {
    font-size: 20px;
  }
  .butter_menu {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }
  #codex_logo {
    width: 60px;
    height: 60px;
  }

}

</style>
