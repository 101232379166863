import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messagesJSON from "./i18nMessage.json"

Vue.use(VueI18n);

const messages = messagesJSON.messages;

const i18n = new VueI18n({    
    locale: 'en', // set locale    
    fallbackLocale: 'fr', // set fallback locale    
    messages, // set locale messages
});

export default i18n;